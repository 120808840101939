import React, { useState, useEffect, createContext, useContext } from "react";
import { useSelector } from "react-redux";

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeLoad, setActiveLoad] = useState(null);
  const loads = useSelector((state) => state.loads);

  useEffect(() => {
    setActiveLoad(loads.at(0))
  }, [loads]);

  const toggleDrawer = () => setShowDrawer(!showDrawer);

  const exposed = {
    showDrawer,
    toggleDrawer,
    activeLoad,
    setActiveLoad
  };

  return <AppContext.Provider value={exposed}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
