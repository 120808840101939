import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { loadUpdated } from "./loadSlice";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import setup from "../../data/setup.json";


const EditLoadform = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadId } = useParams();
  const [loadData, setLoadData] = useState({});

  const load = useSelector((state) =>
    state.loads.find((load) => load.id === loadId)
  );

  useEffect(() => {
    if (load) {
      setLoadData({ ...load });
    }
  }, [load]);

  const handleChange = (e) => {
    setLoadData({
      ...loadData,
      [e.target.name]: e.target.value,
    });
  };

  const saveLoad = () => {
    dispatch(loadUpdated({ ...loadData }));
    navigate(`/review`);
  };

  return (
    <Card style={{ padding: "10px" }}>
      <Stack
        spacing={2}
        direction="column"
      >
        <Typography variant="h5" align="center" gutterBottom>
          Update Load Data
        </Typography>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel name="inspector">Inspector</InputLabel>
        <Select
          labelId="inspector"
          name="inspector"
          value={loadData?.inspector || ""}
          label="Inspector"
          onChange={handleChange}
        >
          {setup?.inspectors?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="vendor">Vendor</InputLabel>
        <Select
          name="vendor"
          placeholder="Vendor"
          value={loadData?.vendor || ""}
          label="Vendor"
          onChange={handleChange}
        >
          {setup?.vendors?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Select
          name="status"
          placeholder="Status"
          value={loadData?.status || ""}
          label="Status"
          onChange={handleChange}
        >
          {setup?.status?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="species">Species</InputLabel>
        <Select
          name="species"
          placeholder="Species"
          value={loadData?.species || ""}
          label="Species"
          onChange={handleChange}
        >
          {setup?.species?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="length">Length (ft)</InputLabel>
        <Select
          name="length"
          value={loadData?.length || ""}
          label="Length (ft)"
          onChange={handleChange}
        >
          {setup?.length?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}'
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="thickness">Thickness (In)</InputLabel>
        <Select
          name="thickness"
          value={loadData?.thickness || ""}
          label="Thickness (In)"
          onChange={handleChange}
        >
          {setup?.thickness?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}"
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <Box textAlign='center'>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          color="primary"
          variant="outlined"
          onClick={saveLoad}
          disabled={
            !loadData?.inspector ||
            !loadData?.vendor ||
            !loadData?.status ||
            !loadData?.length ||
            !loadData?.thickness
          }
        >
          Save Load
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={(() => navigate(`/review`))}
          >Cancel
        </Button>
        </ButtonGroup>
        </Box>
      </Stack>
    </Card>
  );
};

export default EditLoadform;
