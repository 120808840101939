import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../context/appContext";
import { loadAdded } from "./loadSlice";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import setup from "../../data/setup.json";
import defaultLoad from "../../data/defaultLoad.json";


const AddLoadForm = () => {
  const { toggleDrawer, activeLoad } = useAppContext();
  const [loadData, setloadData] = useState(defaultLoad);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeLoad) {
      setloadData(activeLoad);
    }
  }, [activeLoad]);

  // Change load data
  const handleChange = (e) => {
    setloadData({
      ...loadData,
      [e.target.name]: e.target.value,
    });
  };

  const saveLoad = () => {
    dispatch(loadAdded(loadData));
    toggleDrawer();
  };

  return (
    <Card  style={{ padding: "10px" }}>
      <Stack
        spacing={2}
        direction="column"
      >
        <Typography variant="h5" align="center" gutterBottom>
          Add New Load
        </Typography>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel name="inspector">Inspector</InputLabel>
        <Select
          labelId="inspector"
          name="inspector"
          value={loadData?.inspector}
          label="Inspector"
          onChange={handleChange}
        >
          {setup?.inspectors?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="vendor">Vendor</InputLabel>
        <Select
          name="vendor"
          placeholder="Vendor"
          value={loadData?.vendor}
          label="Vendor"
          onChange={handleChange}
        >
          {setup?.vendors?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Select
          name="status"
          placeholder="Status"
          value={loadData?.status}
          label="Status"
          onChange={handleChange}
        >
          {setup?.status?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="species">Species</InputLabel>
        <Select
          name="species"
          placeholder="Species"
          value={loadData?.species}
          label="Species"
          onChange={handleChange}
        >
          {setup?.species?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="length">Length (ft)</InputLabel>
        <Select
          name="length"
          value={loadData?.length}
          label="Length (ft)"
          onChange={handleChange}
        >
          {setup?.length?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}'
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="thickness">Thickness (In)</InputLabel>
        <Select
          name="thickness"
          value={loadData?.thickness}
          label="Thickness (In)"
          onChange={handleChange}
        >
          {setup?.thickness?.map((i) => {
            return (
              <MenuItem key={i} value={i}>
                {i}"
              </MenuItem>
            );
          })}
        </Select>
        </FormControl>

        <Button
          variant="outlined"
          onClick={saveLoad}
          disabled={
            !loadData?.inspector ||
            !loadData?.vendor ||
            !loadData?.status ||
            !loadData?.length ||
            !loadData?.thickness
          }
        >
          Save Load
        </Button>
      </Stack>
    </Card>
  );
};

export default AddLoadForm;
