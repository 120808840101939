import React, { useState } from "react";
import { useAppContext } from "../context/appContext";
import { useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import { boardAdded } from "../features/boards/boardSlice";
import LoadDataSection from "../components/loadData";
import Keypad from "../components/keypad";
import Grades from "../components/grades";
import { BoardTable } from "../features/boards/boardTable";

export default function Home() {
  const matches = useMediaQuery("(min-width:900px)");
  const { activeLoad } = useAppContext();
  const dispatch = useDispatch();

  const gradesMobileCSS = matches ? {} : { height: 200 };

  // Board Data
  const [surfaceMeasure, setSurfaceMeasure] = useState(0);

  const handleGrade = async (grade) => {
    const board = {
      loadId: activeLoad?.id,
      surfaceMeasure: surfaceMeasure,
      thickness: activeLoad?.thickness,
      grade: grade,
    };

    dispatch(boardAdded(board));
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      {/* Grid 4 */}
      <Grid container spacing={3}>
        {/* Grid Item - Load Data */}
        <Grid item xs={12} md={4}>
          {/* Paper */}
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: 300,
              height: 400,
              // border: "3px solid green",
            }}
          >
            <LoadDataSection />
          </Paper>
        </Grid>

        {/* Grid Item - Keypad */}
        <Grid item xs={12} md={4}>
          {/* Paper */}
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              minWidth: 300,
              height: 400,
            }}
          >
            <Keypad surfaceMeasure={surfaceMeasure} setSurfaceMeasure={setSurfaceMeasure} />
          </Paper>
        </Grid>

        {/* Grid Item - Grades */}
        <Grid item xs={12} md={4}>
          {/* Paper */}
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minWidth: 300,
              alignItems: "center",
              height: 400,
              ...gradesMobileCSS,
              // border: "3px solid green",
            }}
          >
            <Grades handleGrade={handleGrade} />
          </Paper>
        </Grid>

        {/* Grid Item - Boards */}
        <Grid item xs={12}>
          {/* Paper */}
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              height: 400,
              // border: "3px solid green",
            }}
          >
            <BoardTable loadData={activeLoad} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
