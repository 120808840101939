import { useState } from "react";

import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AddBoxIcon from '@mui/icons-material/AddBox';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import StorageIcon from '@mui/icons-material/Storage';


import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/appContext";

const NavFooter = () => {
  const [value, setValue] = useState(0);
  const { toggleDrawer } = useAppContext();
  const navigate = useNavigate();

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label="New Load"
          icon={<AddBoxIcon/>}
          onClick={toggleDrawer}
        />
        <BottomNavigationAction
          label="Add Boards"
          icon={<PlaylistAddIcon />}
          onClick={() => navigate("/")}
        />
        <BottomNavigationAction
          label="Records"
          icon={<StorageIcon/>}
          onClick={() => navigate("/review")}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default NavFooter;
