import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";

export const LoadData = ({ loadData }) => {
  if (!loadData?.id) {
    return (
        <Card sx={{ p: 1 }}>
      <Typography variant="body1" gutterBottom>
        Create a new load to start
      </Typography>
      </Card>
    );
  }

  return (
    <Card sx={{ p: 1 }}>
      <Typography variant="h6" gutterBottom>
        {loadData?.vendor}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {loadData?.dateCreated?.slice(0, 10)}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {loadData?.species}, {loadData?.status}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {loadData?.length} x {loadData?.thickness}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Inspector: {loadData?.inspector}
      </Typography>
    </Card>
  );
};
