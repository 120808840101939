import { Outlet } from "react-router-dom";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import SideDrawer from "../components/drawerLeft";
import NavFooter from "../components/nav";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://lancotechnology.com">
        Lanco Tech
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const mdTheme = createTheme();

const AppLayout = () => {
  return (
    <ThemeProvider theme={mdTheme}>

      {/* Box 1 */}
      <Box
        sx={{
          display: "flex",
          // border: "3px solid brown",
        }}
      >
        <SideDrawer />

        {/* Box 2 */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // border: "3px solid red",
          }}
        >

          {/* Container 3 */}
          <Container
            maxWidth="lg"
            sx={{ 
              mt: 4, 
              mb: 4,  
              minHeight: 0,
              minWidth: 0,
              // border: "3px solid orange" 
            }}
          >
            <Outlet />
            <Copyright sx={{ py: 4 }} />
          </Container>
        </Box>
        <NavFooter />
      </Box>
    </ThemeProvider>
  );
};

export default AppLayout;
