import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "@reduxjs/toolkit";

const initialState = [];

const boardsSlice = createSlice({
  name: "boards",
  initialState,
  reducers: {
    boardAdded: {
      reducer(state, action) {
        state.unshift(action.payload);
      },
      prepare(boardData) {
        return {
          payload: {
            id: nanoid(),
            loadId: boardData.loadId,
            surfaceMeasure: boardData.surfaceMeasure,
            thickness: boardData.thickness,
            grade: boardData.grade,
            boardFoot: Number((boardData.surfaceMeasure * boardData.thickness).toFixed()),
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
          },
        };
      },
    },

    boardUpdated(state, action) {
      const { id, loadId, surfaceMeasure, thickness, grade } = action.payload;
      const existingBoard = state.find((load) => load.id === id);

      if (existingBoard) {
        existingBoard.loadId = loadId;
        existingBoard.thickness = thickness;
        existingBoard.surfaceMeasure = surfaceMeasure;
        existingBoard.boardFoot = Number((surfaceMeasure * thickness).toFixed());
        existingBoard.grade = grade;
        existingBoard.dateUpdated = new Date().toISOString();
      }
    },

    boardDeleted(state, action) {
      const { id } = action.payload;
      return (state = state.filter((i) => i.id !== id));
    },
  },
});

export const { boardAdded, boardUpdated, boardDeleted } = boardsSlice.actions;

export default boardsSlice.reducer;
