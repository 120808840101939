import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppContextProvider from "./context/appContext";

import AppLayout from "./pages/appLayout";
import Home from "./pages/home";
import Review from "./pages/review";
import Print from "./pages/print";
import Dashboard from "./pages/dashboard";
import About from "./pages/about";
import NotFound from "./pages/notfound";

import SingleLoadPage from "./features/loads/SingleLoadPage";
import EditLoadform from "./features/loads/EditLoadForm";
import LoadReport from "./features/loads/LoadReport";
import LoadLabel from "./features/loads/LoadLabel";

import { useIP } from "./hooks/useIP";

export default function App() {
  const { data, error, getIP } = useIP();
  const [logged, setLogged] = useState(false)

  useEffect(() => {
    getIP();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && Object.keys(data).length && !logged) {
      const request = {
        method: "POST",
        mode: 'no-cors',
        body: JSON.stringify({app: "LumberGradingDemo", ...data}),
      };
      fetch("https://n8n.lanco.click/webhook/usagetracker", request)
      setLogged(true)
    }

    if (error) {
      console.log(error)

      const request = {
        method: "POST",
        mode: 'no-cors',
        body: JSON.stringify({ app: "LumberGradingDemo" }),
      };
      fetch("https://n8n.lanco.click/webhook/usagetracker", request)
      setLogged(true)
    }

  }, [data, error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Home />} />
            <Route path="review" element={<Review />} />
            <Route path="print" element={<Print />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="about" element={<About />} />
            <Route path="/loads/:loadId" element={<SingleLoadPage />} />
            <Route path="/editLoad/:loadId" element={<EditLoadform />} />
            <Route path="/loadReport/:loadId" element={<LoadReport />} />
            <Route path="/loadLabel/:loadId" element={<LoadLabel />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}
