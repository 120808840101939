import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

import LoadGrid from "../helpers/editLoadGrid";

export const LoadTable = ({ setLoadData }) => {

  const loads = useSelector((state) =>
    state.loads.map((l) => {
      return {
        ...l,
        dateCreated: l.dateCreated.slice(0, 10),
        dateUpdated: l.dateUpdated.slice(0, 10),
        boardCount: state.boards.filter((b) => b.loadId === l.id).length,
      };
    })
  );

  const columns = [
    { field: "inspector", headerName: "Inspector", width: 150, editable: true },
    { field: "vendor", headerName: "Vendor", width: 150, editable: true },
    { field: "status", headerName: "Status", width: 100, editable: true },
    { field: "species", headerName: "Species", width: 150, editable: true },
    { field: "length", headerName: "Length", width: 75, editable: true },
    { field: "thickness", headerName: "Thickness", width: 100, editable: true },
    { field: "boardCount", headerName: "Count", width: 75, editable: false },
    {
      field: "dateCreated",
      headerName: "Created",
      width: 150,
      editable: false,
    },
  ];

  const Table = () => (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Loads
      </Typography>
      <div style={{ display: "flex", height: 325, width: "100%" }}>
        <LoadGrid
          cols={columns}
          data={loads}
          setLoadData={setLoadData}
        />
      </div>
    </>
  );

  return <section className="posts-list">{<Table />}</section>;
};
