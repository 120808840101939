import { useReducer, useEffect } from "react";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "set":
      return action.value;

    case "append":
      return Number("" + state + action.value);
      
    case "back":
      return Number(String(state).slice(0, -1))

    case "clear":
      return 0

    default:
      return state;
  }
};

const keys = [
  { value: 1, action: "append" },
  { value: 2, action: "append" },
  { value: 3, action: "append" },
  { value: 4, action: "append" },
  { value: 5, action: "append" },
  { value: 6, action: "append" },
  { value: 7, action: "append" },
  { value: 8, action: "append" },
  { value: 9, action: "append" },
  { value: 0, action: "append" },
  { value: "<", action: "back" },
  { value: "CLR", action: "clear" },
];

const Keypad = ({ surfaceMeasure, setSurfaceMeasure }) => {
  const [value, dispatch] = useReducer(reducer, 0);

  useEffect(() => {
    setSurfaceMeasure(value)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  
  return (
    <>
      <Typography variant="h5" align="center" sx={{}} gutterBottom>
        Quantity
      </Typography>
      <TextField
        id="outlined-number"
        label="Surface Measure"
        type="number"
        sx={{ my: 2, mx: 1, maxWidth: 400}}
        value={surfaceMeasure}
        onChange={(e) => dispatch({ type: "set", value: e.target.value })}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Box sx={{
          py: 2,
          display: "grid",
          gap: 1,
          gridTemplateColumns: 'repeat(3, 1fr)',
          justifyItems: "center",
          maxWidth: 400
        }}
        >

          
        {keys.map((k) => {
          return (
            <Grid item key={k.value} xs={4} sx={{minWidth: 80}}>
              <Button
                variant="outlined"
                size="large"
                sx={{ minWidth: 80 }}
                onClick={() => dispatch({ type: k.action, value: k.value })}
              >
                {k.value}
              </Button>
            </Grid>
          );
        })}
      </Box>
    </>
  );
};

export default Keypad;
