import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

import { boardAdded, boardUpdated, boardDeleted } from "./boardSlice";
import BoardGrid from "../helpers/editBoardGrid";

export const BoardTable = ({ loadData }) => {
  const dispatch = useDispatch();

  const columns = [
    { field: "surfaceMeasure", headerName: "Surface Measure", flex: 1, editable: true },
    { field: "thickness", headerName: "Thickness", flex: 1, editable: true },
    { field: "boardFoot", headerName: "Board Foot", flex: 1, editable: false },
    { field: "grade", headerName: "Grade", flex: 1, editable: true },
  ];

  const boards = useSelector((state) =>
    state.boards
      .filter((b) => b.loadId === loadData.id)
      .map((b) => {
        return {
          ...b,
          dateCreated: b.dateCreated.slice(0, 10),
          dateUpdated: b.dateUpdated.slice(0, 10),
        };
      })
  );

  const handleEdit = (boardData) => {
    boardData.isNew
      ? dispatch(boardAdded(boardData))
      : dispatch(boardUpdated(boardData));
  };

  const handleDelete = (id) => {
    dispatch(boardDeleted({ id: id }));
  };

  const Table = () => (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Boards
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 325,
          // border: "3px solid green",
        }}
      >
        <BoardGrid
          loadData={loadData}
          data={boards}
          cols={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </div>
    </>
  );

  return <section className="posts-list">{<Table />}</section>;
};
