import { useState } from "react";
import { useAppContext } from "../context/appContext";
import { BoardTable } from "../features/boards/boardTable";
import { LoadData } from "../features/loads/SingleLoadData";
import { LoadTable } from "../features/loads/LoadTable";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";


const Review = () => {
  const { activeLoad } = useAppContext();
  const [loadData, setLoadData] = useState({ ...activeLoad });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minWidth: 300,
            height: 400,
          }}
        >
          <LoadTable setLoadData={setLoadData} loadData={loadData} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minWidth: 300,
            height: 400,
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Active Load
          </Typography>
          <LoadData loadData={loadData} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minWidth: 300,
            height: 400,
          }}
        >
          <BoardTable loadData={loadData} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Review;
