import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "@reduxjs/toolkit";

const initialState = [];

const loadsSlice = createSlice({
  name: "loads",
  initialState,
  reducers: {
    loadAdded: {
      reducer(state, action) {
        const loadNumber = Math.max(...state.map(l => l?.loadNumber), 0) + 1
        state.unshift({...action.payload, loadNumber: loadNumber});
      },
      prepare(loadData) {
        return {
          payload: {
            ...loadData,
            id: nanoid(),
            dateCreated: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
          },
        };
      },
    },

    loadUpdated(state, action) {
      const { id, inspector, vendor, status, species, length, thickness } =
        action.payload;

      const existingLoad = state.find((load) => load.id === id);

      if (existingLoad) {
        existingLoad.inspector = inspector;
        existingLoad.vendor = vendor;
        existingLoad.status = status;
        existingLoad.species = species;
        existingLoad.length = length;
        existingLoad.thickness = thickness;
        existingLoad.dateUpdated = new Date().toISOString();
      }
    },

    loadDeleted(state, action) {
      const { id } = action.payload;
      return (state = state.filter((item) => item.id !== id));
    },
  },
});

export const { loadAdded, loadUpdated, loadDeleted } = loadsSlice.actions;

export default loadsSlice.reducer;
