import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import setup from "../data/setup.json";
const grades = setup.grades

const Grades = ({ handleGrade }) => {
  const matches = useMediaQuery("(min-width:900px)");

  const gradesCSS = matches
    ? {
        flexDirection: "column",
      }
    : {
        flexDirection: "row",
        flexFlow: "wrap",
        justifyContent: "space-evenly"
      };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Grade
      </Typography>

      <Box sx={{  display: "flex", ...gradesCSS }}>
        {grades.map((g) => {
          return (
            <Button
              key={g}
              variant="outlined"
              // size="large"
              sx={{ maxWidth: 200, minWidth: 80, m: 1 }}
              onClick={() => handleGrade(g)}
            >
              {g}
            </Button>
          );
        })}
      </Box>
    </>
  );
};

export default Grades;
