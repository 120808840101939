import { useState, useCallback  } from "react";

const url = "https://geolocation-db.com/json/"
// const url = "https://api.myip.com/"

export const useIP = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getIP = useCallback(() => {
        setData({});
        setLoading(true);
        setError(error);

        fetch(url)
        .then((response) =>
            response
            .json()
            .then((data) => ({
                json: data,
                status: response.status,
            }))
            .then(({ status, json }) => {
                if (status < 200 || status > 299) {
                console.log(json);
                throw new Error(json.message);
                }
                setData(json);
                setLoading(false);
                setError(null);
            })
        )
        .catch((error) => {
            console.log(error);
            setData("");
            setLoading(false);
            setError(error);
        });  
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return {data, loading, error, getIP};
}