import React from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";

const SingleLoadPage = () => {
  const { loadId } = useParams();

  const load = useSelector(state =>
    state.loads.find(load => load.id.toString() === loadId.toString())
  );

  if (!load) {
    return (
      <section>
        <h3>Load not found</h3>
      </section>
    );
  }

  return (
    <section className="load">
        <h2>Load Data</h2>
        <h2>
          {load?.vendor} {load?.dateCreated?.slice(0, 10)}
        </h2>
        <p className="">
          {load?.status} {load?.species}, {load?.length} x {load?.thickness},
          received by {load?.inspector}
        </p>
    </section>
  );
};

export default SingleLoadPage