import { configureStore } from "@reduxjs/toolkit";

import loadsReducer from "../features/loads/loadSlice";
import boardsReducer from "../features/boards/boardSlice";

export default configureStore({
  reducer: {
    loads: loadsReducer,
    boards: boardsReducer,
  },
});
