import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import styles from "../../styles/Report.module.css";

import setup from "../../data/setup.json";

const LoadReport = () => {
  const { loadId } = useParams();

  const load = useSelector((state) =>
    state.loads.find((load) => load.id === loadId)
  );

  const boards = useSelector((state) =>
    state.boards.filter((b) => b.loadId === loadId)
  );

  const grossBF = boards.reduce((p, c) => p + c.boardFoot, 0);

  const cols = ["SM", ...setup.grades, "PCS", "TOTAL BF"];

  const Report = () => {
    return (
      <div className={styles.report}>
        <div className={styles.report_page}>
          <div className={styles.report_header}>
            <h2>Acme Corp - Load Tally Report</h2>
          </div>

          {/* Header */}
          <div className={styles.load_info}>
            <table className={styles.load_info_table}>
              <tbody>
                <tr>
                  <td>Load #:</td>
                  <td>{load?.loadNumber}</td>
                </tr>
                <tr>
                  <td>Species:</td>
                  <td>{load?.species}</td>
                </tr>
                <tr>
                  <td>Thickness:</td>
                  <td>{load?.thickness}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td>{load?.status}</td>
                </tr>
                <tr>
                  <td>Net:</td>
                  <td>
                    {boards
                      .filter((b) => b.grade !== "BG")
                      .reduce((p, c) => p + c.boardFoot, 0)}
                  </td>
                </tr>
                <tr>
                  <td>Gross:</td>
                  <td>{grossBF}</td>
                </tr>
              </tbody>
            </table>
            <table className={styles.load_info_table}>
              <tbody>
                <tr>
                  <td>Received:</td>
                  <td>{load?.dateCreated.slice(0, 10)}</td>
                </tr>
                <tr>
                  <td>Inspector:</td>
                  <td>{load?.inspector}</td>
                </tr>
                <tr>
                  <td>Customer:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Vendor:</td>
                  <td>{load?.vendor}</td>
                </tr>
                <tr>
                  <td>Location:</td>
                  <td>Yard</td>
                </tr>
                <tr>
                  <td>Length:</td>
                  <td>{load?.length}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          {/* End Header */}

          {/* Table */}
          <div className={styles.load_data}>
            <table className={styles.load_data_table}>
              <tbody>
                {/* Table Header */}
                <tr>
                  {cols.map((title, i) => {
                    return <th key={i}>{title}</th>;
                  })}
                </tr>

                {/* Table Body Rows */}
                {[...Array(23).keys()].map((sm) => {
                  return (
                    <tr key={sm}>
                      {/* Surface Measure Column (SM) */}
                      <td key={sm}>{sm}</td>

                      {/* Board Count Cells */}
                      {cols
                        .slice(1, setup.grades.length + 1)
                        .map((grade, i) => {
                          return (
                            <td key={i}>
                              {
                                boards.filter(
                                  (b) => b.surfaceMeasure === sm && b.grade === grade
                                ).length
                              }
                            </td>
                          );
                        })}

                      {/* Piece Count */}
                      <td key={sm+"a"}>
                        {boards.filter((b) => b.surfaceMeasure === sm).length}
                      </td>

                      {/* BF Total */}
                      <td key={sm+"b"}>
                        {boards
                          .filter((b) => b.surfaceMeasure === sm)
                          .reduce((a, b) => a + b.boardFoot, 0)}
                      </td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
          {/* End Table */}

          {/* Load Summary */}
          <div className={styles.load_summary}>
            <table className={styles.load_summary_table}>
              <tbody>
                {/* Table Body Rows */}

                <tr>
                  <td>Pieces</td>
                  {/* Board Count Cells */}
                  {cols.slice(1, setup.grades.length + 1).map((grade, i) => {
                    return (
                      <td key={i}>
                        {boards.filter((b) => b.grade === grade).length}
                      </td>
                    );
                  })}

                  {/* Piece Count */}
                  <td>{boards.length}</td>

                  {/* BF Total */}
                  <td></td>
                </tr>
                <tr>
                  <td>Footage</td>
                  {/* Board Count Cells */}
                  {cols.slice(1, setup.grades.length + 1).map((grade, i) => {
                    return (
                      <td key={i}>
                        {boards
                          .filter((b) => b.grade === grade)
                          .reduce((a, b) => a + b.boardFoot, 0)}
                      </td>
                    );
                  })}

                  {/* Piece Count */}
                  <td></td>

                  {/* BF Total */}
                  <td>{boards.reduce((a, b) => a + b.boardFoot, 0)}</td>
                </tr>
                <tr>
                  <td>Percent</td>
                  {/* Board Count Cells */}
                  {cols.slice(1, setup.grades.length + 1).map((grade, i) => {
                    return (
                      <td key={i}>
                        {(
                          (boards
                            .filter((b) => b.grade === grade)
                            .reduce((a, b) => a + b.boardFoot, 0) /
                            grossBF) *
                          100
                        ).toFixed(1)}%
                      </td>
                    );
                  })}
                  <td></td>
                  <td>100%</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* End Load Summary */}
        </div>
      </div>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // height: "100%",
        // border: "3px solid yellow",
      }}
    >
      {/* <PDFViewer width={500} height={750}> */}
      {/* <PDFViewer width={375} height={565}>
        <MyDocument />
      </PDFViewer> */}
      <Report />
    </Box>
  );
};

export default LoadReport;
