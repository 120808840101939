import * as React from "react";

import Box from "@mui/material/Box";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useAppContext } from "../context/appContext";
import AddLoadForm from "../features/loads/AddLoadForm";


export default function SideDrawer() {
  const { showDrawer, toggleDrawer } = useAppContext();

  return (
    <SwipeableDrawer anchor={"left"} open={showDrawer} onClose={toggleDrawer}>
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: 300,
          }}>
        <AddLoadForm />
      </Box>
    </SwipeableDrawer>
  );
}
