import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import bwipjs from "bwip-js";

import Box from "@mui/material/Box";
import styles from "../../styles/Label.module.css";

import setup from "../../data/setup.json";

const LoadLabel = () => {
  const { loadId } = useParams();
  const [src, setImageSrc] = useState(false);

  const load = useSelector((state) =>
    state.loads.find((load) => load.id === loadId)
  );

  const boards = useSelector((state) =>
    state.boards.filter((b) => b.loadId === loadId)
  );

  // Create a barcode
  useEffect(() => {
    console.log(load);
    if (load.id) {
      let canvas = document.createElement("canvas");
      bwipjs.toCanvas(canvas, {
        bcid: "code128", // Barcode type
        text: load?.id  || "no data", // Text to encode
        scale: 3, // 3x scaling factor
        height: 10, // Bar height, in millimeters
        includetext: true, // Show human-readable text
        textxalign: "center", // Always good to set this
      });
      setImageSrc(canvas.toDataURL("image/png"));
    }
  }, [load]);

  const cols = setup.grades;

  const Report = () => {
    return (
      <div className={styles.label_container}>
        <div className={styles.pack_header}>
          <div className={styles.label_load}>{load?.loadNumber}</div>
          <div className={styles.label_vendor}>{load?.vendor}</div>
          <div className={styles.label_ftg}>
            Ftg: {boards.reduce((p, c) => p + c.boardFoot, 0)}
          </div>
        </div>

        <div className={styles.pack_header}>
          <div className={styles.pack_details} style={{ textAlign: "left" }}>
            {load?.status} {load?.species}
          </div>
          <div className={styles.pack_details} style={{ textAlign: "center" }}>
            {load?.thickness}" x {load?.length}'
          </div>
          <div className={styles.pack_details} style={{ textAlign: "right" }}>
            {load?.dateCreated.slice(0, 10)}
          </div>
        </div>

        <div className={styles.pack_label}>
          <div>
            {cols.slice(0, 3).map((grade) => {
              return (
                <div>
                  {grade}:{" "}
                  {boards
                    .filter((b) => b.grade === grade)
                    .reduce((a, b) => a + b.boardFoot, 0)}
                </div>
              );
            })}
          </div>
          <div>
            {cols.slice(3, 6).map((grade) => {
              return (
                <div>
                  {grade}:{" "}
                  {boards
                    .filter((b) => b.grade === grade)
                    .reduce((a, b) => a + b.boardFoot, 0)}
                </div>
              );
            })}
          </div>

          <div>
            <div>Kiln:___________</div>
            <div>Date In:________</div>
            <div>Truck:_________</div>
          </div>
        </div>

        <div className={styles.barcode_container}>
        <img className={styles.barcode} src={src} alt={`qr code from load id`} />
        </div>
      </div>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // height: "100%",
        // border: "3px solid yellow",
      }}
    >
      {/* <PDFViewer width={500} height={750}> */}
      {/* <PDFViewer width={375} height={565}>
        <MyDocument />
      </PDFViewer> */}
      <Report />
    </Box>
  );
};

export default LoadLabel;
