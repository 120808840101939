import { useAppContext } from "../context/appContext";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LoadData } from "../features/loads/SingleLoadData";


const LoadDataSection = () => {
  const { activeLoad, setActiveLoad } = useAppContext();
  const loads = useSelector((state) => state.loads);

  // Change load data
  const handleChange = (e) => {
    const load = loads.find((load) => load.id === e.target.value);
    setActiveLoad(load);
  };

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Active Load
      </Typography>

      <FormControl sx={{ minWidth: 120, mb: 2 }}>
        <InputLabel name="load">Load</InputLabel>
        <Select
          labelId="Load"
          name="load"
          value={activeLoad?.id || ""}
          label="Load"
          onChange={handleChange}
        >
          {loads?.map((l) => {
            return (
              <MenuItem key={l.id} value={l.id}>
                {`${l.vendor} - ${l.dateCreated.slice(0, 10)}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <LoadData loadData={activeLoad} />
    </>
  );
};

export default LoadDataSection;
