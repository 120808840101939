import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadDeleted } from "../loads/loadSlice";

import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

export default function CrudGrid({ cols, data, setLoadData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSummaryClick = (id) => () => {
    navigate(`/loadReport/${id}`);
  };

  const handleLabelClick = (id) => () => {
    navigate(`/loadLabel/${id}`);
  };

  const handleEditClick = (id) => () => {
    navigate(`/editLoad/${id}`);
  };

  const handleDeleteClick = (id) => () => {
    dispatch(loadDeleted({ id: id }));
  };

  const columns = [
    { field: "vendor", headerName: "Vendor", flex: 2, editable: true },
    { field: "status", headerName: "Status", flex: 1, editable: true },
    { field: "species", headerName: "Species", flex: 1, editable: true },
    { field: "length", headerName: "Length", flex: 1, editable: true },
    { field: "thickness", headerName: "Thickness", flex: 1, editable: true },
    { field: "boardCount", headerName: "Count", flex: 1, editable: false },
    { field: "inspector", headerName: "Inspector", flex: 1, editable: true },
    {
      field: "dateCreated",
      headerName: "Created",
      width: 150,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, ...params }) => {
        const deletable = params?.row?.boardCount ? false : true;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color={"error"}
            onClick={handleDeleteClick(id)}
            disabled={!deletable}
          />,
        ];
      },
    },
    {
      field: "reports",
      type: "actions",
      headerName: "Reports",
      flex: 1,
      cellClassName: "reports",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<QrCode2OutlinedIcon />}
            label="label"
            className="textPrimary"
            onClick={handleLabelClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<TableChartOutlinedIcon />}
            label="summary"
            className="textPrimary"
            onClick={handleSummaryClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        mt: 2,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = data.filter((row) => selectedIDs.has(row.id));
          setLoadData(selectedRows[0]);
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
